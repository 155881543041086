import React from 'react'

import LtiApplyTheme from './LtiApplyTheme'
import jwtDecode from "jwt-decode";
import {View} from "@instructure/ui-view";
import Messages from "./Messages";
import {Button, IconButton} from "@instructure/ui-buttons";
import {SourceCodeEditor} from "@instructure/ui-source-code-editor";
import {fetchWithAuth, humanDuration, timeSince} from "./utils";
import DeepLinkReturn from "./DeepLinkReturn";
import {Spinner} from "@instructure/ui-spinner";
import {Text} from "@instructure/ui-text";
import {Flex} from "@instructure/ui-flex";
import {Tooltip} from "@instructure/ui-tooltip";
import {Menu} from "@instructure/ui-menu";
import {IconMoreLine} from "@instructure/ui-icons";
import {SimpleSelect} from "@instructure/ui-simple-select";
import {ScreenReaderContent} from "@instructure/ui-a11y-content";

const TEMPLATE = "const msg = 'Hello World';\nconsole.log(msg);"

class App extends React.Component {
    state = {
        token: null,
        placement: null,
        brandConfig: null,
        highContrast: null,
        language: 'javascript',
        value: TEMPLATE,
        editable: true,
        messages: [],
        loading: true,
        adding: true,
        returnUrl: null,
        // So we can display a spinner for adding/updating
        isBusy: false,
        readonly: false
    }

    editor = null
    _jwt = null

    componentDidMount() {
        // if null or undefined don't do this.
        if (typeof demoStart !== 'undefined' && demoStart !== null
            && typeof demoDuration !== 'undefined' && demoDuration !== null) {
            const demoEnd = demoStart + demoDuration
            const now = Date.now()
            if (demoEnd < now) {
                this.addMessage({variant: 'warning', body: `Evaluation Expired. Editing Locked`})
                this.setState({readonly: true})
            } else {
                const remaining = demoEnd - now
                this.addMessage({
                    variant: 'info',
                    body: `Evaluation Mode. Editing locks in ${humanDuration(remaining)}`
                })
            }
        }
        // This is the JWT from the LTI launch.
        if (typeof JWT !== 'undefined') {
            this.updateData(JWT)
        } else {
            this.addMessage({variant: 'error', body: 'You must launch this tool from Canvas'})
            this.setState({loading: false})
        }
    }


    /**
     * Load the current content.
     * @returns {Promise<void>}
     */
    loadContent = async () => {
        const response = await fetchWithAuth("/api/get", {}, JWT)
        if (!response.ok) {
            this.addMessage({
                variant: 'error',
                body: `Failed to load existing content. Please re-try. Error code: ${response.status}`
            })
            return
        }
        const value = await response.json()
        this.setState({
            value: value.code,
            language: value.language,
            editable: value.editable,
            updatedAt: value.updatedAt,
            updatedBy: value.updatedBy
        })
    }

    updateData = async (token) => {
        this._jwt = jwtDecode(token)
        let returnUrl
        if (this._jwt["https://purl.imsglobal.org/spec/lti/claim/message_type"] === 'LtiDeepLinkingRequest') {
            returnUrl = this._jwt["https://purl.imsglobal.org/spec/lti-dl/claim/deep_linking_settings"]["deep_link_return_url"]
        }
        const isInstructor =
            this._jwt['https://purl.imsglobal.org/spec/lti/claim/roles'].includes('http://purl.imsglobal.org/vocab/lis/v2/membership#Instructor') ||
            this._jwt['https://purl.imsglobal.org/spec/lti/claim/roles'].includes('http://purl.imsglobal.org/vocab/lis/v2/institution/person#Administrator')
        const user = this._jwt['sub']
        const placement = this._jwt['https://www.instructure.com/placement']
        const embedded = placement === 'editor_button'
        this.setState({
            token,
            placement,
            isInstructor,
            user,
            returnUrl,
            embedded,
            // Enable autoresize when we're embedded into some other content.
            autoResize: embedded,
            brandConfig: this._jwt['https://purl.imsglobal.org/spec/lti/claim/custom'].com_instructure_brand_config_json_url,
            highContrast: this._jwt['https://purl.imsglobal.org/spec/lti/claim/custom'].canvas_user_prefers_high_contrast === "true",
        })
        if (!returnUrl) {
            // We're editing an existing one here.
            // We can't do this load on LTI launch as we don't have the token at that point in time :-(
            this.setState({
                loading: true,
                adding: false
            })
            this.loadContent().finally(() => this.setState({loading: false}))
        } else {
            // This is needed so that content isn't shown by default and only once we're setup the state do
            // we show the content (this is because we have unmanaged inputs).
            this.setState({loading: false})
        }
    }

    handleRef = (ref) => {
        this.form = ref
    }

    onClose = idx => {
        this.setState((prev) => ({
            messages: prev.messages.filter((_, i) => i !== idx)
        }))
    }

    addMessage = (message) => {
        this.setState((prev) => ({
            messages: [...prev.messages, message]
        }))
    }

    setValue = (value) => {
        this.setState({
            value
        })
        // Warn before leaving once we've modified the content
        addEventListener("beforeunload", this.beforeUnloadListener, {capture: true});
    }


    setAutoResize = (e) => {
        this.setState({autoResize: e.target.checked})
    }

    beforeUnloadListener = (event) => {
        // This shouldn't be necessary, but otherwise when calling window.close we end up in here twice :-(
        removeEventListener("beforeunload", this.beforeUnloadListener, {capture: true});
        event.preventDefault();
        return (event.returnValue = "");
    }

    onAdd = async () => {
        removeEventListener("beforeunload", this.beforeUnloadListener, {capture: true});
        if (!this.state.returnUrl) {
            this.addMessage({variant: 'error', body: `No return URL. Was tool correctly launched?`})
            return
        }
        this.setState({isBusy: true})
        try {
            const form = new FormData();
            form.set("language", this.state.language)
            form.set("code", this.state.value)
            form.set("editable", this.state.editable)
            const response = await fetchWithAuth("/api/deep-link", {
                body: form,
                method: 'POST'
            }, this.state.token)
            if (!response.ok) {
                this.addMessage({variant: 'error', body: `Failed to sign token. Error code: ${response.status}`})
                return
            }
            const json = await response.json()
            this.setState({
                jwt: json.JWT
            }, () => this.form.submit())
        } finally {
            this.setState({isBusy: false})
        }
    }

    onUpdate = async () => {
        removeEventListener("beforeunload", this.beforeUnloadListener, {capture: true});
        this.setState({isBusy: true})
        try {
            const form = new FormData();
            form.set("language", this.state.language)
            form.set("code", this.state.value)
            form.set('editable', this.state.editable)
            // We need the JWT with the ID set here.
            const response = await fetchWithAuth("/api/update", {
                body: form,
                method: 'POST'
            }, JWT)
            if (!response.ok) {
                this.addMessage({variant: 'error', body: `Failed to update. Error code: ${response.status}`})
                return
            }
            let reloaded = false
            // Try to find our own iframe and reload it.
            for (let i = 0; i < window.opener.frames.length; i++) {
                try {
                    window.opener.frames[i].location.reload()
                    reloaded = true
                    window.opener.postMessage(
                        {
                            subject: 'lti.showAlert',
                            alertType: 'success',
                            body: `Saved.`,
                            title: 'Code',
                        },
                        '*'
                    )
                    break;
                } catch {
                    // Ignore: there doesn't seem to be a way to detect if it will work.
                }
            }
            if (!reloaded) {
                // Fallback message if we couldn't find our page.
                // This can happen when the page showing the content has been navigated somewhere else.
                window.opener.postMessage(
                    {
                        subject: 'lti.showAlert',
                        alertType: 'warning',
                        body: `Saved. Unable to reload the page.`,
                        title: 'Code',
                    },
                    '*'
                )
            }
            window.close()
        } finally {
            this.setState({isBusy: false})
        }

    }

    onCancelUpdate = (e) => {
        // Editing is done in a new window so just close the window.
        window.close()
    }

    onCancelAdd = (e) => {
        // To not add content we just go to the return URL
        window.location = this._jwt['https://purl.imsglobal.org/spec/lti/claim/launch_presentation']['return_url']
    }

    renderSave = () => {
        return (this.state.adding) ?
            <Button color='primary' disabled={this.state.readonly || this.state.isBusy}
                    onClick={this.onAdd}>Add</Button> :
            <Button color='primary' disabled={this.state.readonly || this.state.isBusy}
                    onClick={this.onUpdate}>Update</Button>
    }

    renderCancel = () => {
        return this.state.adding ?
            // When launched from the add module content (link_selection) you can't cancel it complains about
            // not finding any content, it works fine from module_menu.
            <Button interaction={this.state.placement === 'link_selection' ? 'disabled' : 'enabled'}
                    onClick={this.onCancelAdd}>Cancel</Button> :
            <Button onClick={this.onCancelUpdate}>Cancel</Button>

    }

    render() {
        const {adding, highContrast, brandConfig, isBusy} = this.state;
        return (
            <LtiApplyTheme url={brandConfig} highContrast={highContrast}>
                <View as='div' padding='small medium' height='100%'>
                    <Messages messages={this.state.messages} onClose={this.onClose}/>
                    {this.state.loading ? <Spinner renderTitle='Loading content'/> :
                        <Flex direction='column' height='100%' gap='xx-small'>
                            <Flex.Item shouldGrow shouldShrink padding='x-small'>
                                <SourceCodeEditor label='content' defaultValue={this.state.value}
                                                  onChange={this.setValue} lineWrapping lineNumbers
                                                  foldGutter
                                                  highlightActiveLine highlightActiveLineGutter
                                                  language={this.state.language}
                                                  ref={(component) => {
                                                      this.editor = component
                                                  }}
                                                  height='100%'
                                />
                            </Flex.Item>
                            <Flex.Item padding='x-small'>
                                <Flex gap='small'>
                                    <Flex.Item>
                                        <Menu placement='top start' trigger={
                                            <IconButton screenReaderLabel='Options'><IconMoreLine/></IconButton>
                                        }>
                                            <Menu.Item
                                                selected={this.state.editable}
                                                type='checkbox'
                                                onSelect={(_e, _value, selected) => this.setState({editable: selected})}
                                            >
                                                Editable
                                            </Menu.Item>
                                        </Menu>
                                    </Flex.Item>
                                    <Flex.Item>
                                        <SimpleSelect defaultValue={this.state.language} renderLabel={<ScreenReaderContent>Language</ScreenReaderContent>} isInline onChange={(_, {id: language}) => this.setState({language})}>
                                            <SimpleSelect.Group renderLabel='Programming Languages'>
                                                <SimpleSelect.Option id='javascript' value='javascript'>JavaScript</SimpleSelect.Option>
                                                <SimpleSelect.Option id='typescript' value='typescript'>TypeScript</SimpleSelect.Option>
                                                <SimpleSelect.Option id='python' value='python'>Python</SimpleSelect.Option>
                                                <SimpleSelect.Option id='rust' value='rust'>Rust</SimpleSelect.Option>
                                                <SimpleSelect.Option id='go' value='go'>Go</SimpleSelect.Option>
                                            </SimpleSelect.Group>
                                            <SimpleSelect.Group renderLabel='Databases'>
                                                <SimpleSelect.Option id='postgresql' value='postgresql'>PostgreSQL</SimpleSelect.Option>
                                                <SimpleSelect.Option id='sqlite' value='sqlite'>SQLite</SimpleSelect.Option>
                                            </SimpleSelect.Group>
                                            <SimpleSelect.Group renderLabel='Others'>
                                                <SimpleSelect.Option id='bash' value='bash'>Bash</SimpleSelect.Option>
                                                <SimpleSelect.Option id='fetch' value='fetch'>Fetch API</SimpleSelect.Option>
                                                <SimpleSelect.Option id='http' value='http'>HTTP</SimpleSelect.Option>
                                            </SimpleSelect.Group>
                                        </SimpleSelect>
                                    </Flex.Item>
                                    <Flex.Item>
                                        <Button onClick={() => {
                                            this.editor.indentAll()
                                        }}>
                                            Re-indent
                                        </Button>
                                    </Flex.Item>
                                    <Flex.Item>
                                        {adding || <Text size='small'>
                                            Updated {this.relativeDateTime(this.state.updatedAt)} by {this.state.updatedBy}</Text>}
                                    </Flex.Item>
                                    <Flex.Item shouldGrow>
                                    </Flex.Item>
                                    {isBusy &&
                                        <Flex.Item>
                                            <Spinner size='x-small' renderTitle='Busy'/>
                                        </Flex.Item>
                                    }
                                    <Flex.Item>
                                        {this.renderSave()}
                                    </Flex.Item>
                                    <Flex.Item>
                                        {this.renderCancel()}
                                    </Flex.Item>
                                </Flex>
                            </Flex.Item>
                        </Flex>
                    }
                    <DeepLinkReturn returnUrl={this.state.returnUrl} elementRef={ref => this.form = ref}
                                    jwt={this.state.jwt}/>
                </View>
            </LtiApplyTheme>
        )
    }

    relativeDateTime(dateTime) {
        const date = new Date(Date.parse(dateTime))
        return <Tooltip renderTip={date.toLocaleString()}>
            {timeSince(date)}
        </Tooltip>
    }
}

export default App
