import React from 'react'
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import App from './App'

if (process.env.NODE_ENV !== 'development') {
    Sentry.init({
        dsn: 'https://08d8c444d5e147ef4f6f08bfb6abadfd@o459966.ingest.sentry.io/4505811978747904',
        integrations: [new Integrations.BrowserTracing()],
        // This is the git SHA
        release: process.env.REACT_APP_GIT_SHA,

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    })
}

const container = document.getElementById('app');
const root = createRoot(container);
root.render(<App/>);
